import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";
import { onMounted } from "vue";
import { templateRef } from "@vueuse/core";


export default {
  __name: 'index',
  props: {
  items: {
    type: Array,
    required: true,
  },
  title: {
    type: String,
    required: false,
  },
  usePriority: {
    type: Boolean,
    required: false,
    default: true,
  },
  verticalCenterContentInRow: {
    type: Boolean,
    required: false,
    default: true,
  },
},
  setup(__props) {

const props = __props


const { reveal } = useScrollTriggerAnimation();
const tpl_titleWrapper = templateRef("tpl_titleWrapper");
const tpl_title = templateRef("tpl_title");
onMounted(() => {
  if (props.title) {
    reveal(tpl_title.value, tpl_titleWrapper.value, "bottom", { fade: true });
  }
});

return { __sfc: true,props, reveal, tpl_titleWrapper, tpl_title }
}

}