import { ref, onMounted, nextTick, onBeforeUnmount } from "vue";
import { templateRef } from "@vueuse/core";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";


export default {
  __name: 'index',
  props: {
  category: {
    type: String,
    required: true,
  },
  date: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: true,
  },
  media: {
    type: Object,
    required: true,
  },
  mediaSizes: {
    type: Object,
    required: true,
  },
  target: {
    type: [String, Object],
    required: true,
  },
  loading: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "eager", "lazy"].indexOf(value) !== -1;
    },
    default: "auto",
  },
},
  setup(__props) {

const props = __props



const isVideoLauncher = ref(typeof props.target !== "string");
const showVPModal = ref(false);
const tpl_figcaption = templateRef("tpl_figcaption");
const tpl_figcaptionInner = templateRef("tpl_figcaptionInner");
const dateISO8601 = props.date.replace(/ /g, "T");

const dateIT = ref(
  props.date
    ? new Date(dateISO8601).toLocaleString("it-IT", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })
    : ""
);

const encodedDate = ref(new Date(dateISO8601).toDateString());

const { reveal } = useScrollTriggerAnimation();

const slug = props.title.toLowerCase().replace(/ /g, "-");

function onHashChange(event) {
  showVPModal.value = isVideoLauncher.value;
}

function handleClick(event) {
  if (isVideoLauncher.value) {
    window.location.hash = slug;
  }
}

function onModalHide() {
  showVPModal.value = false;
  window.location.hash = "";
}

onMounted(() => {
  nextTick(() => {
    if (isVideoLauncher.value) {
      window.addEventListener("hashchange", onHashChange);
      if (window.location.hash.replace("#", "") == slug) {
        onHashChange();
      }
    }

    reveal(tpl_figcaptionInner.value, tpl_figcaption.value, "right", {
      fade: true,
    });
  });
});

onBeforeUnmount(() => {
  if (isVideoLauncher.value) {
    window.removeEventListener("hashchange", onHashChange);
  }
});

return { __sfc: true,props, isVideoLauncher, showVPModal, tpl_figcaption, tpl_figcaptionInner, dateISO8601, dateIT, encodedDate, reveal, slug, onHashChange, handleClick, onModalHide }
}

}