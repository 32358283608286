import { ref, computed, watch, onMounted } from "vue";
import { useContext } from "@nuxtjs/composition-api";

import {
  useFullscreen,
  useMediaControls,
  useIdle,
  useIntersectionObserver,
  useMediaQuery,
} from "@vueuse/core";

import useTailwindCss from "/composables/useTailwindCss";

import { useDataLayer } from "@/composables/useDataLayer";
import { useRoute } from "@nuxtjs/composition-api";


export default {
  __name: 'index',
  props: {
  src: {
    type: String,
    required: true,
  },
  poster: {
    type: String,
    required: false,
  },
  autoplay: {
    type: Boolean,
    required: false,
    default: false,
  },
  pauseWhenOffscreen: {
    type: Boolean,
    required: false,
    default: true,
  },
  fill: {
    type: Boolean,
    required: false,
    default: false,
  },
  endCallback: {
    type: Function,
    required: false,
    default: function () {},
  },
  playMuted: {
    type: Boolean,
    required: false,
    default: false,
  },
  controlsOffset: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props) {

const props = __props

const route = useRoute();

const { pushDataLayer } = useDataLayer();



const isIOS = ref(false);
const { screens } = useTailwindCss();
const isMDScreen = useMediaQuery(`(min-width: ${screens.md.min})`);
const tpl_video = ref();
const tpl_root = ref();

const { $cookies } = useContext();

const { isFullscreen, enter, toggle } = useFullscreen(tpl_root);
const { playing, currentTime, duration, muted, buffered } = useMediaControls(
  tpl_video,
  {
    src: {
      src: props.src,
    },
  }
);

const targetIsVisible = ref(false);
const { stop } = useIntersectionObserver(
  tpl_root,
  ([{ isIntersecting }], observerElement) => {
    targetIsVisible.value = isIntersecting;
  }
);

if (props.pauseWhenOffscreen) {
  let wasPlaying = false;
  watch(targetIsVisible, (newVal, oldVal) => {
    if (newVal) {
      if (wasPlaying) {
        playing.value = true;
      }
    } else {
      wasPlaying = playing.value;
      playing.value = false;
    }
  });
}

watch(muted, (val) => {
  $cookies.set("ft-muted", val, {
    path: "/",
    maxAge: 60 * 60 * 24 * 7 * 4, // 4 week
  });
});

const { idle } = useIdle(4000);
const formatDuration = (seconds) =>
  new Date(1000 * seconds).toISOString().substr(14, 5);

const endBuffer = computed(() =>
  buffered.value.length > 0 ? buffered.value[buffered.value.length - 1][1] : 0
);

function trackClick(event) {
  let progress = event.layerX / event.target.clientWidth;
  progress = Math.max(progress, 0);
  progress = Math.min(progress, 1);
  currentTime.value = progress * duration.value;
}

const time = computed(() => {
  const d = duration.value || 0;
  const c = currentTime.value || 0;
  if (isMDScreen.value) {
    return `${formatDuration(c)} / ${formatDuration(d)}`;
  } else {
    return `-${formatDuration(d - c).toString()}`;
  }
});

const attrs = computed(() => {
  let obj = {};

  if (props.autoplay) {
    obj.autoplay = "";
  }

  if (isIOS.value) {
    obj.controls = "";
  } else {
    obj.playsinline = "";
  }

  return obj;
});

const unwatch = watch(playing, (newValue, oldValue) => {
  if (newValue) {
    pushDataLayer({
      event: "play_video",
      page_type: route.value.name,
      page_url: route.value.path,
    });
    unwatch();
  }
});

// Change initial media properties
onMounted(() => {
  isIOS.value = /iPhone|iPad|iPod/.test(navigator.userAgent);

  if (props.playMuted == true || $cookies.get("ft-muted")) {
    muted.value = true;
  }

  if (isIOS && props.autoplay) playing.value = true;
});

return { __sfc: true,route, pushDataLayer, props, isIOS, screens, isMDScreen, tpl_video, tpl_root, $cookies, isFullscreen, enter, toggle, playing, currentTime, duration, muted, buffered, targetIsVisible, stop, idle, formatDuration, endBuffer, trackClick, time, attrs, unwatch }
}

}