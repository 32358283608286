import { render, staticRenderFns } from "./index.vue?vue&type=template&id=146d3fb6&"
import script from "./index.vue?vue&type=script&setup=true&lang=js&"
export * from "./index.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VectorIcon: require('/var/www/html/fantini/frontend/components/vector/icon/index.vue').default,Multimedia: require('/var/www/html/fantini/frontend/components/multimedia/index.vue').default,ModalVideoPlayer: require('/var/www/html/fantini/frontend/components/modal/video-player/index.vue').default,Btn: require('/var/www/html/fantini/frontend/components/btn/index.vue').default})
