import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3b1fd5ce&"
import script from "./index.vue?vue&type=script&setup=true&lang=js&"
export * from "./index.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=3b1fd5ce&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnCtaPlay: require('/var/www/html/fantini/frontend/components/btn/cta-play/index.vue').default,BtnCircle: require('/var/www/html/fantini/frontend/components/btn/circle/index.vue').default})
