export default {
  __name: 'index',
  props: {
  visible: {
    type: Boolean,
    required: false,
    default: false,
  },
  src: {
    type: String,
    required: true,
  },
  poster: {
    type: String,
    required: false,
  },
  transition: {
    type: String,
    required: false,
    validator: function (value) {
      return ["fade", "slide-right"].indexOf(value) !== -1;
    },
    default: "slide-right",
  },
},
  setup(__props) {

const props = __props



return { __sfc: true,props }
}

}